"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapPexelsResponseToStockImage = void 0;
const mapPexelsResponseToStockImage = (pexelsPage) => {
    return {
        page: pexelsPage.page,
        totalResults: pexelsPage.total_results,
        nextPage: pexelsPage.next_page,
        prevPage: pexelsPage.prev_page,
        photos: pexelsPage.photos.map(photo => ({
            id: photo.id,
            width: photo.width,
            height: photo.height,
            url: photo.url,
            photographer: photo.photographer,
            avgColor: photo.avg_color,
            src: photo.src,
            alt: photo.alt,
        })),
    };
};
exports.mapPexelsResponseToStockImage = mapPexelsResponseToStockImage;
