import { FormReplyStatus } from "../../generated/graphql";

export enum ChatEvents {
  chatMessageTyping = "chatMessageTyping",
  chatMessageTyped = "chatMessageTyped",
  createMessage = "createMessage",
  deleteMessage = "deleteMessage",
  chatRoomRead = "chatRoomRead",
  chatMessageReactionCreated = "chatMessageReactionCreated",
  chatMessageReactionDeleted = "chatMessageReactionDeleted",
}

export type ChatRoomReader = {
  chatRoomId: string;
  chatRoomMemberId: string;
  tenantName: string;
};

export type ChatMessageTyping = {
  firstname: string;
  lastname: string;
  chatRoomId: string;
  userProfileId: string;
  chatRoomType: ChatRoomType;
  tenantName: string;
};

export enum ChatRoomType {
  Group = "GROUP",
  Private = "PRIVATE",
}

export type ChatRoomMessage = {
  id: string;
  chatRoomId: string;
  userProfileId: string | null;
  content: string;
  createdAt: Date;
  updatedAt: Date;
  chatRoomMessageId: string | null;
  formReplyId: string | null;
  deletedById: string | null;
  deletedAt: Date | null;
};

export type ChatMessageCreated = ChatRoomMessage & {
  author?: {
    userId: string;
    userType: (typeof UserType)[keyof typeof UserType];
    picture: string | null;
    pictureOTC: string | null;
    firstname: string;
    lastname: string;
    tenantName: string;
  } | null;
  attachment?: {
    id: string;
    s3Path: string;
    otcPath: string | null;
    type: (typeof NewsAttachmentType)[keyof typeof NewsAttachmentType];
  } | null;
  quote?:
    | (ChatRoomMessage & {
        author: {
          userId: string;
          userType: (typeof UserType)[keyof typeof UserType];
          picture: string | null;
          pictureOTC: string | null;
          firstname: string;
          lastname: string;
          tenantName: string;
        };
      })
    | null;
  forms?: Array<{
    assigneeId?: string;
    id: string;
    title: string;
  }>;
  infoPages?: Array<{
    id: string;
    title: string;
  }>;
  formReply?: {
    id: string;
    form: {
      id: string;
      title: string;
      assigneeId: string | null;
    };
    status: FormReplyStatus;
  } | null;
};

type ChatRoomMessageReaction = {
  content: string;
  messageId: string;
  memberId: string;
}

export type ChatRoomMessageReactionEvent = Omit<ChatRoomMessageReaction, 'memberId'> & {
  tenantName: string;
  chatRoomId: string;
  userId: string;
}

export const UserType = {
  EMPLOYEE: "EMPLOYEE",
  CLIENT: "CLIENT",
  RELATIVE: "RELATIVE",
};

export const NewsAttachmentType = {
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO",
  AUDIO: "AUDIO",
  DOCUMENT: "DOCUMENT",
};
