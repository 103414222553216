"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stockImageApi = void 0;
const pexelsApi_1 = require("./pexelsApi");
exports.stockImageApi = {
    getPageForList: async ({ apiToken, nextPageUrl }) => {
        const requestResult = await pexelsApi_1.pexelsApi.getPageOfCuratedImages({ apiToken, nextPageUrl });
        if (requestResult.status !== 200) {
            return {
                status: requestResult.status,
                statusText: requestResult.statusText,
            };
        }
        return requestResult.data;
    },
    getPageForSearch: async ({ apiToken, searchValue, nextPageUrl }) => {
        const requestResult = await pexelsApi_1.pexelsApi.getPageOfSearchedImages({
            apiToken,
            searchValue,
            nextPageUrl,
        });
        if (requestResult.status !== 200) {
            return {
                status: requestResult.status,
                statusText: requestResult.statusText,
            };
        }
        return requestResult.data;
    },
    cleanFileNameForOTCUpload: fileName => {
        return pexelsApi_1.pexelsApi.cleanFileNameForOTCUpload(fileName);
    },
};
